import { EventJoinerModel } from '@index/models/event-joiner';
import { createAction, props, union } from '@ngrx/store';

export enum JoinerActionTypes {
  JoinerLoadList = '[Joiner] Load List',
  JoinerLoadListSuccess = '[Joiner] Load List Success',
  JoinerLoadListError = '[Joiner] Load List Error',
}

export const joinerLoadList = createAction(
  JoinerActionTypes.JoinerLoadList,
  props<{ eventId: string }>(),
);
export const joinerLoadListSuccess = createAction(
  JoinerActionTypes.JoinerLoadListSuccess,
  props<{ joiners: EventJoinerModel[], eventId: string}>(),
);
export const joinerLoadListError = createAction(
  JoinerActionTypes.JoinerLoadListError,
  props<{ error: unknown }>(),
);

export const allJoiners = union({
  joinerLoadList,
  joinerLoadListSuccess,
  joinerLoadListError,
});

export type JoinerActionsUnion = typeof allJoiners;
